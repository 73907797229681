import { ReactNode, useContext } from "react";

import { CopyableElement } from "../../../design-system";
import { bytesConverter, productClassCode, standardFormatDate } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { Deposit } from "../../model";
import { ApisContext } from "../../services";
import { DepositModeIcon, DepositTypeIcon } from "../DepositIcon";
import DepositSection, { DepositSectionData } from "../DepositSection/DepositSection";

const DepositCreatorMember = ({ member }: { member: { name: string; longNumber: string } }): ReactNode => {
    return (
        <>
            {member.name} (<CopyableElement value={member.longNumber}>{member.longNumber}</CopyableElement>)
        </>
    );
};

const DepositCreatorUser = ({ user }: { user: { firstName: string; lastName: string; role: Deposit.CreatorUserRole } }): ReactNode => {
    const { translation } = useI18n();

    return (
        <>
            {user.firstName} {user.lastName} ({translation.creatorUserRole[user.role]})
        </>
    );
};

const DepositCreator = ({ deposit }: { deposit: Deposit }): ReactNode => {
    return (
        <span className="flex">
            {deposit.creator.user && (
                <>
                    <DepositCreatorMember member={deposit.creator.member} /> - <DepositCreatorUser user={deposit.creator.user} />
                </>
            )}
            {!deposit.creator.user && <DepositCreatorMember member={deposit.creator.member} />}
        </span>
    );
};

const getLogiboxData = (translation: Translation, deposit: Deposit): DepositSectionData[] => {
    if (!deposit.logibox) {
        return [];
    }

    if ("logiboxAdh" in deposit.logibox) {
        return [
            { title: translation.depositStore.logiboxAdhWithLabel, value: deposit.logibox.logiboxAdh },
            { title: translation.depositStore.logiboxAppWithLabel, value: deposit.logibox.logiboxApp },
            { title: translation.depositStore.quantitySupport, value: deposit.logibox.mediaType }
        ];
    } else {
        return [
            { title: translation.depositStore.logibox1WithLabel, value: deposit.logibox.logibox1 },
            { title: translation.depositStore.logibox2WithLabel, value: deposit.logibox.logibox2 },
            { title: translation.depositStore.quantitySupport, value: deposit.logibox.mediaType },
            { title: translation.depositStore.additionnalInformation, value: deposit.logibox.note }
        ];
    }
};

const getFileData = (locale: string, translation: Translation, deposit: Deposit): DepositSectionData[] => {
    const fileData = [];

    if (deposit.filesCount) {
        fileData.push({ title: translation.depositStore.numberDeposit, value: deposit.filesCount.toString() });
    }

    if (deposit.filesSizeInBytes) {
        const convert = bytesConverter(locale, translation.file);

        fileData.push({
            title: translation.depositStore.depositStoreSize,
            value: convert(deposit.filesSizeInBytes)
        });
    }

    return fileData;
};

export default function DepositCardContent({ deposit }: { deposit: Deposit }) {
    const apis = useContext(ApisContext);
    const { locale, translation } = useI18n<Translation>();

    return (
        <div className="flex flex-col gap-4 p-4 font-light text-color-disabled">
            <DepositSection
                title={translation.depositRequest}
                data={[
                    { title: translation.deposit.dateLabel, value: standardFormatDate(deposit.createdAt) },
                    {
                        title: translation.creatorIdentityLabel,
                        value: <DepositCreator deposit={deposit} />,
                        copyable: false
                    }
                ]}
            />
            <DepositSection
                title={translation.workDescription}
                data={[
                    { title: translation.depositNameLabel, value: deposit.name },
                    { title: translation.subtitleLabel, value: deposit.subtitle },
                    { title: translation.depositStore.version, value: deposit.workVersion },
                    { title: translation.depositStore.dateVersion, value: standardFormatDate(deposit.workVersionDate) },
                    { title: translation.depositStore.workNature, value: translation.deposit.workNature[deposit.workNature] },
                    {
                        title: translation.depositStore.workType,
                        value: `${apis.deposit.workTypes.find(workType => workType.value === deposit.workType)?.code} - ${translation.deposit.workType[deposit.workType]}`
                    },
                    { title: translation.depositStore.workNationality, value: translation.country[deposit.countryCode] },
                    {
                        title: translation.depositStore.productClass,
                        value: `${productClassCode(apis.deposit, deposit.productClass)} - ${translation.deposit.productClass[deposit.productClass]}`
                    }
                ]}
            />
            <DepositSection
                title={translation.depositSeal}
                data={[
                    { title: translation.depositStore.sealedDate, value: standardFormatDate(deposit.sealedAt) },
                    {
                        title: translation.depositTypeLabel,
                        value: (
                            <span className="flex">
                                {!!deposit.subscriptionTypeCode && (
                                    <>
                                        {translation.deposit.subscriptionTypeCode[deposit.subscriptionTypeCode]}
                                        <span className="p-1 h-6 w-6">
                                            <DepositTypeIcon type={deposit.subscriptionTypeCode} />
                                        </span>
                                    </>
                                )}
                                {!deposit.subscriptionTypeCode && "-"}
                            </span>
                        )
                    },
                    {
                        title: translation.depositModeLabel,
                        value: (
                            <span className="flex">
                                {translation.deposit.depositType[deposit.depositType]}
                                <span className="p-1 h-6 w-6">
                                    <DepositModeIcon mode={deposit.depositType} />
                                </span>
                            </span>
                        )
                    },
                    ...getLogiboxData(translation, deposit),
                    ...getFileData(locale, translation, deposit)
                ]}
            />
        </div>
    );
}
