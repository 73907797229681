import { Checkout, CheckoutCreation, Invoice, Link, PaginatedInvoices, Product } from "../model";
import { doGet, doPost, download } from "./api.service";

export type Download = {
    name: string;
    blob: Blob;
};

export type MemberInformation = {
    _links: {
        invoices?: Link;
        credits?: Link;
        createInvoice?: Link;
        createCheckout?: Link;
    };
};

export function getMemberInformation(memberId: string): Promise<MemberInformation> {
    return doGet(`${process.env.REACT_APP_ORDER_API_URL}/v1/members/${memberId}`);
}

export function getMemberInvoices(memberInformation: MemberInformation): (() => Promise<PaginatedInvoices>) | undefined {
    const link = memberInformation._links.invoices;

    if (!link) {
        return undefined;
    }

    return () => doGet<PaginatedInvoices>(link.href);
}

export function downloadInvoicePdf(invoice: Invoice): (() => Promise<Download>) | undefined {
    const link = invoice._links.downloadPdf;

    if (!link) {
        return undefined;
    }

    return () => download(link.href);
}

export function getCredits(memberInformation: MemberInformation): (() => Promise<Product[]>) | undefined {
    const link = memberInformation._links.credits;

    if (!link) {
        return undefined;
    }

    return () => doGet<Product[]>(link.href);
}

export function createInvoice(memberInformation: MemberInformation): ((products: { product: Product; quantity: number }[]) => Promise<Invoice>) | undefined {
    const link = memberInformation._links.createInvoice;

    if (!link) {
        return undefined;
    }

    return (products: { product: Product; quantity: number }[]) =>
        doPost<Invoice>(link.href, { items: products.map(({ product, quantity }) => ({ reference: product.reference, quantity })) });
}

export function createCheckout(memberInformation: MemberInformation): ((checkoutCreation: CheckoutCreation) => Promise<Checkout>) | undefined {
    const link = memberInformation._links.createCheckout;

    if (!link) {
        return undefined;
    }

    return (checkoutCreation: CheckoutCreation) => doPost<Checkout>(link.href, checkoutCreation);
}
