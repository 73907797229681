import {
    DepositCreators,
    DepositCreatorsSide,
    getContributorsByMember,
    getRepresentativesByMember,
    Layout,
    Loader,
    Member,
    PersonsIcon,
    Representative,
    Representatives,
    RepresentativesSide,
    Tabs,
    useApi,
    useI18n,
    UserInfo
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { MemberContext } from "../../contexts";
import { Translation } from "../../i18n";

export enum DepositCreatorsTabs {
    CREATOR = "creator",
    INVITATIONS = "invitations"
}

export default function DepositCreatorsPage(): JSX.Element {
    const [contributors, setContributors] = useState<UserInfo[]>();
    const [representatives, setRepresentatives] = useState<Representative[]>();

    const { translation } = useI18n<Translation>();
    const { member } = useContext(MemberContext);
    const { fetchApi } = useApi();

    const loadInfos = async (memberDetails?: Member) => {
        if (memberDetails) {
            const fetchRepresentatives = getRepresentativesByMember(memberDetails);
            const fetchContributors = getContributorsByMember(memberDetails);

            if (fetchContributors) {
                await fetchApi(fetchContributors, setContributors);
            }
            if (fetchRepresentatives) {
                await fetchApi(fetchRepresentatives, setRepresentatives);
            }
        }
    };

    useEffect(() => {
        void loadInfos(member);
    }, [member]);

    if (!member || !contributors || !representatives) {
        return <Loader />;
    }

    const tabs = [
        {
            key: DepositCreatorsTabs.CREATOR,
            title: translation.creators,
            component: (
                <>
                    <DepositCreators member={member} contributors={contributors} refresh={() => loadInfos(member)} />
                    <Representatives member={member} representatives={representatives} />
                </>
            )
        },
        {
            key: DepositCreatorsTabs.INVITATIONS,
            title: translation.invitations,
            component: <></>
        }
    ];

    return (
        <Layout
            title={translation.depositCreatorsAndRepresentatives}
            icon={<PersonsIcon className="w-6 h-8 fill-primary" />}
            rightContent={
                <>
                    <DepositCreatorsSide contributors={contributors} />
                    <RepresentativesSide representatives={representatives} />
                </>
            }
        >
            <Tabs tabs={tabs} />
        </Layout>
    );
}
