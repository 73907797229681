import { Button, InputSelectCheckbox, ListOption, Member, MemberHolder, Representative, TrashIcon, useI18n } from "@vaultinum/app-sdk";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { formatUserList } from "../../helpers";
import { Translation } from "../../i18n";

export default function ListDeposit({
    field,
    title,
    information,
    inputTitle,
    users,
    member,
    isRepresentative
}: {
    field: string;
    title: string;
    information: ReactNode;
    inputTitle: string;
    users?: Representative[] | MemberHolder[];
    member?: Member;
    isRepresentative?: boolean;
}): JSX.Element {
    const { setValue, getValues, watch } = useFormContext();
    const { translation } = useI18n<Translation>();

    return (
        <div className="w-full space-y-2.5 text-label">
            {member && (
                <>
                    <h3 className="text-sm font-bold">{translation.titularityDeposit}</h3>
                    <div className="flex gap-2">
                        <p className="text-sm">{`${member?.id} - ${member?.name}`}</p>
                        <p className="font-light italic">({translation.you})</p>
                    </div>
                </>
            )}

            <h3 className="text-sm font-bold pt-7">{title}</h3>
            <div className="min-w-28 bg-white mb-2.5 p-4">
                <ul>
                    {watch(field).length ? (
                        watch(field).map((option: ListOption) => (
                            <li key={option.id} className="flex items-center justify-between px-2.5 py-1.5 hover:bg-slate-100">
                                <span className="pr-2.5 text-label">{option.label}</span>
                                <Button
                                    variant="iconButton"
                                    onClick={() =>
                                        setValue(
                                            field,
                                            getValues(field).filter((item: ListOption) => item.id !== option.id)
                                        )
                                    }
                                    icon={<TrashIcon className="w-4 h-4 fill-primary" />}
                                />
                            </li>
                        ))
                    ) : (
                        <span className="text-label text-sm px-2.5 py-1.5">
                            {isRepresentative ? translation.noDepositRepresentativeSelected : translation.noHolder}
                        </span>
                    )}
                </ul>
            </div>
            <div className="p-4 bg-primary-xlight">
                <InputSelectCheckbox
                    list={formatUserList(users ?? [])}
                    listFiltered={getValues(field)}
                    handleList={list => setValue(field, list)}
                    placeholder={translation.findOrSelect}
                    inputLabel={inputTitle}
                    noResultLabel={translation.noResult}
                />
            </div>
            {information && <div className="pt-12">{information}</div>}
        </div>
    );
}
