import {
    ApisContextProvider,
    BreadCrumbContextProvider,
    getEnvironment,
    I18n,
    InterdepositContextProvider,
    Loader,
    ScrollToTop,
    Toast,
    useI18n,
    UserContext,
    UserContextProvider
} from "@vaultinum/app-sdk";
import { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { PrivatePageLayout } from "./components";
import { MemberContextProvider } from "./contexts";
import { fr, Translation } from "./i18n";
import {
    CREATE_DEPOSIT_PAGE,
    CREDIT_PAGE,
    DEPOSIT_CREATORS_PAGE,
    DEPOSIT_PAGE,
    DEPOSIT_STORE_CREATE_DEPOSIT_PAGE,
    DEPOSIT_STORE_PAGE,
    DEPOSIT_STORE_RESUME_DEPOSIT_PAGE,
    DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE,
    HOME_PAGE,
    LOGIN_PAGE,
    PAYMENT_AND_INVOICES_PAGE,
    RESET_PASSWORD_PAGE,
    TrackingEvent,
    trackingService,
    UPDATE_DEPOSIT_PAGE,
    USER_INFO_PAGE,
    WALLET_PAGE
} from "./services";
import { DepositStorePage, HomePage, WalletPage } from "./views/private";
import CreditPage from "./views/private/CreditPage";
import DepositCreatorsPage from "./views/private/DepositCreatorsPage";
import { CreateDepositPage, DepositPage, UpdateDepositPage } from "./views/private/DepositPage";
import InvoicesAndPaymentsPage from "./views/private/InvoicesAndPaymentPage";
import UserInfoPage from "./views/private/UserInfoPage";
import { DepositStoreCreateDepositPage, DepositStoreResumeDepositPage, DepositStoreUpdateDepositPage } from "./views/private/WalletPage";
import { LoginPage, PasswordPage } from "./views/public";

function PrivateRoutes(): JSX.Element {
    const { translation } = useI18n<Translation>();
    const location = useLocation();

    useEffect(() => {
        trackingService.track(TrackingEvent.NAVIGATION, { path: `${location.pathname}${location.search}` });
    }, [location]);

    document.title = translation.applicationTitles[getEnvironment()];

    return (
        <ApisContextProvider>
            <MemberContextProvider>
                <BreadCrumbContextProvider>
                    <PrivatePageLayout>
                        <ScrollToTop />
                        <Routes>
                            <Route
                                element={
                                    <InterdepositContextProvider>
                                        <Outlet />
                                    </InterdepositContextProvider>
                                }
                            >
                                <Route path={HOME_PAGE} element={<HomePage />} />
                                <Route path={CREDIT_PAGE} element={<CreditPage />} />
                                <Route path={PAYMENT_AND_INVOICES_PAGE} element={<InvoicesAndPaymentsPage />} />
                                <Route path={USER_INFO_PAGE} element={<UserInfoPage />} />
                                <Route path={WALLET_PAGE} element={<WalletPage />} />
                                <Route path={DEPOSIT_PAGE} element={<DepositPage />} />
                                <Route path={DEPOSIT_STORE_PAGE} element={<DepositStorePage />} />
                                <Route path={CREATE_DEPOSIT_PAGE} element={<CreateDepositPage />} />
                                <Route path={UPDATE_DEPOSIT_PAGE} element={<UpdateDepositPage />} />
                                <Route path={DEPOSIT_STORE_CREATE_DEPOSIT_PAGE} element={<DepositStoreCreateDepositPage />} />
                                <Route path={DEPOSIT_STORE_UPDATE_DEPOSIT_PAGE} element={<DepositStoreUpdateDepositPage />} />
                                <Route path={DEPOSIT_STORE_RESUME_DEPOSIT_PAGE} element={<DepositStoreResumeDepositPage />} />
                                <Route path={DEPOSIT_CREATORS_PAGE} element={<DepositCreatorsPage />} />
                                <Route path="*" element={<Navigate to={HOME_PAGE} />} />
                            </Route>
                        </Routes>
                    </PrivatePageLayout>
                </BreadCrumbContextProvider>
            </MemberContextProvider>
        </ApisContextProvider>
    );
}

function PublicRoutes(): JSX.Element {
    const { translation } = useI18n<Translation>();
    document.title = translation.applicationTitles[getEnvironment()];

    return (
        <Routes>
            <Route path={LOGIN_PAGE} element={<LoginPage />} />
            <Route path={RESET_PASSWORD_PAGE} element={<PasswordPage />} />
            <Route path="*" element={<Navigate to={LOGIN_PAGE} />} />
        </Routes>
    );
}

function HandleRoutes(): JSX.Element {
    const { user, isLoading } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectUrl = urlParams.get("redirectUrl");

    useEffect(() => {
        if (!user && location.pathname !== LOGIN_PAGE && !isLoading) {
            window.location.replace(`${LOGIN_PAGE}?redirectUrl=${location.pathname + location.search}`);
        }
    }, [user, location, isLoading]);

    if (isLoading) {
        return <Loader />;
    }

    if (user) {
        if (redirectUrl) {
            navigate(redirectUrl);
        }
        return <PrivateRoutes />;
    }

    return <PublicRoutes />;
}

function AppRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <UserContextProvider>
                <I18n locale="fr" locales={{ fr }}>
                    <Toast />
                    <HandleRoutes />
                </I18n>
            </UserContextProvider>
        </BrowserRouter>
    );
}
export default AppRouter;
