import { Member, MemberApi, MemberHolder, Representative, UserInfo } from "../model";
import { doGet, doPost } from "./api.service";

const checkUrl = (url: string) => {
    if (!url.startsWith(`${process.env.REACT_APP_MEMBER_API_URL}`)) {
        throw new Error("Invalid url: not an Interdeposit API");
    }
};

const absolute = (path: string): string => {
    return `${process.env.REACT_APP_MEMBER_API_URL}${path}`;
};

export function getMemberApi(): Promise<MemberApi> {
    return doGet(absolute("/v1/api"));
}

export function getPaginatedMemberList(queryParams?: Record<string, string>): Promise<Member.PaginatedList> {
    const url = absolute("/v1/members");

    return doGet<Member.PaginatedList>(url, queryParams);
}

export function getMembersStats() {
    const url = absolute("/v1/members-stats");

    return doGet<Member.Stats>(url);
}

export function getMember(url: string) {
    checkUrl(url);

    return doGet<Member>(url);
}

export function getMemberDetails(memberApi: MemberApi): (() => Promise<Member>) | undefined {
    const link = memberApi._links.connectedMember;

    if (!link) {
        return undefined;
    }

    return () => doGet<Member>(link.href);
}

export function getMemberHolders(member: Member): (() => Promise<MemberHolder[]>) | undefined {
    const link = member._links.holders;

    if (!link) {
        return undefined;
    }

    return () => doGet<MemberHolder[]>(link.href);
}

export function getContributorsByMember(member: Member): (() => Promise<UserInfo[]>) | undefined {
    const link = member._links.contributors.available;

    if (!link) {
        return undefined;
    }

    return () => doGet<UserInfo[]>(member._links.contributors.href);
}

export function getRepresentativesByMember(member: Member): (() => Promise<Representative[]>) | undefined {
    const link = member._links.representatives.available;

    if (!link) {
        return undefined;
    }

    return () => doGet<Representative[]>(member._links.representatives.href);
}

export function getActiveContibutorsByMember(member: Member): (() => Promise<UserInfo[]>) | undefined {
    const link = member._links.activeContributors.available;

    if (!link) {
        return undefined;
    }

    return () => doGet<UserInfo[]>(member._links.activeContributors.href);
}

export function disableContributor(user: UserInfo): (() => Promise<void>) | undefined {
    const link = user._links.disable;

    if (!link.available) {
        return undefined;
    }

    return () => doPost(link.href);
}

export function enableContributor(user: UserInfo): (() => Promise<void>) | undefined {
    const link = user._links.enable;

    if (!link.available) {
        return undefined;
    }

    return () => doPost(link.href);
}

export function getMemberMandates(member: Member): (() => Promise<MemberHolder[]>) | undefined {
    const link = member._links.mandates;

    if (!link.available) {
        return undefined;
    }

    return () => doGet<MemberHolder[]>(link.href);
}

export function getConnectedUser(memberApi: MemberApi): (() => Promise<UserInfo>) | undefined {
    const link = memberApi._links.connectedUser;

    if (!link) {
        return undefined;
    }

    return () => doGet<UserInfo>(link.href);
}

export function sendMandateRequest(member: Member): (() => Promise<void>) | undefined {
    const link = member._links.requestMandate;
    if (!link?.available) {
        return undefined;
    }

    return () => doPost(link.href);
}

export function sendRepresentativeRequest(member: Member): (() => Promise<void>) | undefined {
    const link = member._links.requestRepresentative;

    if (!link?.available) {
        return undefined;
    }

    return () => doPost(link.href);
}
