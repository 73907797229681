import { LayoutSide, LayoutSideElement } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { Representative } from "../../model";

export default function RepresentativesSide({ representatives }: { representatives?: Representative[] }): JSX.Element {
    const { translation, translate } = useI18n<Translation>();

    const representativeStatus = (): LayoutSideElement[] => {
        const status: LayoutSideElement[] = [];

        if (representatives) {
            const activeStatus = representatives.filter(representative => representative.request.status === Representative.Status.ACCEPTED);
            const refusedStatus = representatives.filter(representative => representative.request.status === Representative.Status.REFUSED);
            const canceledStatus = representatives.filter(representative => representative.request.status === Representative.Status.CANCELED);
            const revokedStatus = representatives.filter(representative => representative.request.status === Representative.Status.REVOKED);
            const sentStatus = representatives.filter(representative => representative.request.status === Representative.Status.SENT);

            if (activeStatus.length) {
                status.push({ label: translate(translation.representativesActiveCount, activeStatus.length) });
            }

            if (refusedStatus.length) {
                status.push({ label: translate(translation.representativesRefusedCount, refusedStatus.length) });
            }

            if (canceledStatus.length) {
                status.push({ label: translate(translation.representativesCanceledCount, canceledStatus.length) });
            }

            if (revokedStatus.length) {
                status.push({ label: translate(translation.representativesRevokedCount, revokedStatus.length) });
            }

            if (sentStatus.length) {
                status.push({ label: translate(translation.representativesSentCount, sentStatus.length) });
            }
        }

        return status;
    };

    return <LayoutSide title={translation.representatives} sideElements={representativeStatus()} />;
}
