import {
    Layout,
    Loader,
    Member,
    MemberApi,
    MemberCredit,
    MemberCredits,
    MemberSideCredits,
    MoneyIcon,
    getMemberApi,
    getMemberCredit,
    getMemberDetails,
    useApi,
    useI18n
} from "@vaultinum/app-sdk";
import { useEffect, useState } from "react";

import { Translation } from "../../i18n";

export default function CreditPage() {
    const [memberCredits, setMemberCredits] = useState<MemberCredit | undefined>();
    const { fetchApi, isLoading } = useApi();
    const { translation } = useI18n<Translation>();

    const loadInfos = async () => {
        await fetchApi<MemberApi>(getMemberApi, async (memberApi: MemberApi) => {
            if (memberApi) {
                const memberFn = getMemberDetails(memberApi);
                if (memberFn) {
                    await fetchApi(memberFn, async (response: Member) => {
                        await fetchApi(
                            () => getMemberCredit(response.id),
                            creditResponse => setMemberCredits(creditResponse)
                        );
                    });
                }
            }
        });
    };

    useEffect(() => {
        void loadInfos();
    }, []);

    if (!memberCredits) {
        return <Loader />;
    }

    return (
        <Layout
            title={translation.credits}
            icon={<MoneyIcon className="w-6 h-8 fill-primary" />}
            rightContent={<MemberSideCredits memberCredits={memberCredits} />}
            isLoading={isLoading}
        >
            <MemberCredits memberCredits={memberCredits} refresh={loadInfos} />
        </Layout>
    );
}
