import {
    BoxContainer,
    Country,
    Deposit,
    Info,
    InterdepositContext,
    ListOption,
    Loader,
    TickIcon,
    bytesConverter,
    getDepositById,
    getTotalSize,
    useI18n
} from "@vaultinum/app-sdk";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { contractTypeTranslation, subscriptionTranslation } from "../../helpers";
import { Translation } from "../../i18n";

type DepositValue = {
    label: string;
    value: string;
};

const ListDepositValues = ({ depositValues }: { depositValues: DepositValue[] }): JSX.Element => (
    <ul className="flex flex-col space-y-3.5 text-label">
        {depositValues.map(item => (
            <li key={item.label}>
                <p className="text-sm">
                    <span>
                        <strong>{item.label}</strong>
                    </span>{" "}
                    {item.value || "-"}
                </p>
            </li>
        ))}
    </ul>
);

const RightDisplay = ({ holders }: { holders: Deposit.Holder[] | ListOption[] }): JSX.Element => (
    <ul className="flex flex-col gap-1">
        {holders.map((holder: ListOption | Deposit.Holder) => {
            if ("memberId" in holder) {
                return (
                    <li key={holder.longNumber}>
                        <span>
                            {holder.name} ({holder.longNumber})
                        </span>
                    </li>
                );
            }
            return (
                <li key={holder.id}>
                    <span>{holder.label}</span>
                </li>
            );
        })}
    </ul>
);

export default function StepRecap(): JSX.Element {
    const { getValues, register } = useFormContext();
    const { locale, translation } = useI18n<Translation>();
    const format = bytesConverter(locale, translation.file);
    const listOfFiles = getValues("listOfFiles") as File[];
    const [depositInformation, setDepositInformation] = useState<Deposit>();
    const { deposit } = useContext(InterdepositContext);

    const loadDepositInformation = async () => {
        if (deposit) {
            const getDeposit = await getDepositById(deposit._links.self.href);
            setDepositInformation(getDeposit);
        }
    };

    useEffect(() => {
        void loadDepositInformation();
    }, []);

    const SECOND_PART = [
        {
            label: translation.label(translation.depositStore.title),
            value: getValues("name")
        },
        {
            label: translation.label(translation.depositStore.subtitle),
            value: getValues("subtitle")
        },
        {
            label: translation.label(translation.versionNumber),
            value: getValues("workVersion")
        },
        {
            label: translation.depositStore.dateVersion,
            value: getValues("workVersionDate")
        },
        {
            label: translation.depositStore.workNature,
            value: translation.deposit.workNature[getValues("workNature") as Deposit.WorkNature]
        },
        {
            label: translation.depositStore.productClass,
            value: translation.deposit.productClass[getValues("productClass") as Deposit.ProductClass]
        },
        {
            label: translation.label(translation.work.type),
            value: translation.deposit.workType[getValues("workType") as Deposit.WorkType]
        },
        {
            label: translation.depositStore.workNationality,
            value: `${getValues("countryCode")} - ${translation.country[getValues("countryCode") as Country]}`
        },
        {
            label: translation.depositStore.numberDeposit,
            value: getValues("listOfFiles").length
        },
        {
            label: translation.label(translation.depositSize),
            value: format(getTotalSize(listOfFiles), { decimal: 0 })
        }
    ];

    if (!depositInformation?.holders) {
        return <Loader />;
    }

    return (
        <div className="space-y-12">
            <div className="space-y-2">
                <div className="grid grid-cols-3 gap-2 w-full">
                    <BoxContainer title={translation.deposit.subscriptionType} icon={<TickIcon className="fill-green-light" />}>
                        <span className="text-sm text-label">{subscriptionTranslation(getValues("subscriptionTypeCode"), translation)}</span>
                    </BoxContainer>
                    <BoxContainer title={translation.depositMode} icon={<TickIcon className="fill-green-light" />}>
                        <span className="text-sm text-label">{translation.deposit.depositType[getValues("type") as Deposit.Type]}</span>
                    </BoxContainer>
                    <BoxContainer title={translation.contractType} icon={<TickIcon className="fill-green-light" />}>
                        <span className="text-sm text-label">{contractTypeTranslation(getValues("contractType"), translation)}</span>
                    </BoxContainer>
                </div>

                <BoxContainer title={translation.titularity} icon={<TickIcon className="fill-green-light" />}>
                    <RightDisplay holders={depositInformation.holders} />
                </BoxContainer>
                <BoxContainer title={translation.yourProduct} icon={<TickIcon className="fill-green-light" />}>
                    <ListDepositValues depositValues={SECOND_PART} />
                </BoxContainer>
            </div>

            <div className="flex items-start">
                <input type="checkbox" {...register("agreements")} id="agreements" name="agreements" className="mt-1" />
                <label className="pl-2.5 text-sm text-label" htmlFor="agreements">
                    {translation.agreementSealed}
                </label>
            </div>
            <div>
                <Info type="warning">{translation.infoWarningUpload}</Info>
            </div>
        </div>
    );
}
