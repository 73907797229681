import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import { FormFieldsInput, FormFieldsRadio, RepresentativeStatus } from "..";
import { BoxContainer, Button, Card, Info, SadBox, SIZE_MAP, TickIcon } from "../../../design-system";
import { standardFormatDate } from "../../helpers";
import yup from "../../helpers/yup-extended";
import { Translation, useI18n } from "../../i18n";
import { Member, Representative } from "../../model";
import { sendMandateRequest, sendRepresentativeRequest } from "../../services";
import DepositSection from "../DepositSection/DepositSection";

enum EmailType {
    REPRESENTATIVE = "representative",
    MANDATES = "mandates"
}

export default function Representatives({ member, representatives }: { member: Member; representatives?: Representative[] }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const [addRepresentative, setAddRepresentative] = useState<boolean>(false);

    const RepresentativeSchema: ObjectSchema<FieldValues> = yup.object({
        userEmail: yup.string().isEmailFormatValid(translation.emailFormatError).required(translation.requiredField),
        emailType: yup.string().oneOf([EmailType.REPRESENTATIVE, EmailType.MANDATES]).required(translation.requiredField)
    });

    const {
        control,
        formState: { errors, isValid },
        watch
    } = useForm<FieldValues>({
        mode: "onChange",
        resolver: yupResolver(RepresentativeSchema)
    });

    const canSendRepresentativeRequest = !!sendRepresentativeRequest(member);
    const canSendMandateRequest = !!sendMandateRequest(member);

    const canSendRequest = canSendRepresentativeRequest || canSendMandateRequest;

    return (
        <div>
            <h2 className="text-base font-semibold uppercase pb-5">{translation.representativeName(member)}</h2>
            {!representatives?.length ? (
                <div className="pt-8 gap-y-2.5 flex flex-col justify-center items-center">
                    <SadBox className={SIZE_MAP.xxl} />
                    <span className="text-light-grey">{translation.noRepresentative}</span>
                </div>
            ) : (
                <div className="flex flex-col gap-2.5">
                    <Info type="neutral">{translation.representativeInfo}</Info>
                    <ul className="flex flex-col gap-y-2.5">
                        {representatives.map(representative => (
                            <li key={representative.id}>
                                <Card
                                    title={<span className="font-bold" children={representative.name} />}
                                    rightContent={
                                        <div className="flex gap-x-2.5">
                                            <span className="text-light-grey">|</span>
                                            <span className="px-1.5">
                                                <RepresentativeStatus status={representative.request.status} size="sm" />
                                            </span>
                                        </div>
                                    }
                                >
                                    <div className="flex flex-col gap-4 p-4 font-light text-color-disabled">
                                        <DepositSection
                                            title={translation.representatives}
                                            data={[
                                                { title: translation.member.memberNumberLabel, value: representative.fullNumber },
                                                {
                                                    title: translation.label(translation.socialRaison),
                                                    value: representative.siren ?? "-"
                                                },
                                                {
                                                    title: translation.label(translation.personInCharge),
                                                    value: `${representative.owner.firstName} ${representative.owner.lastName}`
                                                },
                                                { title: translation.label(translation.email), value: representative.owner.email }
                                            ]}
                                        />
                                        <DepositSection
                                            title={translation.mandate}
                                            data={[
                                                {
                                                    title: translation.label(translation.state),
                                                    value: translation.representativeStatus[representative.request.status]
                                                },
                                                {
                                                    title: translation.label(translation.mandatDate),
                                                    value: standardFormatDate(representative.request.requestedAt)
                                                },
                                                {
                                                    title: translation.label(translation.revokedDate),
                                                    value: standardFormatDate(representative.request.repliedAt) ?? "-"
                                                }
                                            ]}
                                        />
                                    </div>
                                </Card>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="flex flex-col justify-center py-5 items-center">
                <Button onClick={() => setAddRepresentative(true)} label={translation.createInvitation} disabled={!canSendRequest} />
            </div>
            {addRepresentative && (
                <div>
                    <BoxContainer title={translation.createInvitation} icon={<TickIcon className="fill-green-light" />}>
                        <form onSubmit={() => {}} className="space-y-5">
                            <div>
                                <div className="flex flex-col">
                                    <label className="block mb-1.5 text-sm font-semibold">{translation.invitedRole}</label>
                                    <FormFieldsRadio
                                        radioGroupName="emailType"
                                        control={control}
                                        options={[
                                            {
                                                value: EmailType.REPRESENTATIVE,
                                                title: translation.asRepresentative,
                                                disabled: !canSendRepresentativeRequest
                                            },
                                            {
                                                value: EmailType.MANDATES,
                                                title: translation.asMandate,
                                                disabled: !canSendMandateRequest
                                            }
                                        ]}
                                    />
                                </div>
                            </div>

                            <FormFieldsInput
                                fields={[
                                    {
                                        name: "userEmail",
                                        label: translation.memberEmail,
                                        type: "email",
                                        disabled: !watch("emailType")
                                    }
                                ]}
                                control={control}
                                errors={errors}
                            />
                            <Info type="primary" children={translation.representativeAddInfo} />
                            <div className="flex flex-col justify-center items-center gap-7">
                                <Button label={translation.sendInvitation} type="submit" disabled={!isValid} />
                                <Button variant="link" label={translation.cancel} onClick={() => setAddRepresentative(false)} underline />
                            </div>
                        </form>
                    </BoxContainer>
                </div>
            )}
        </div>
    );
}
