import { Info, InterdepositContext, useI18n } from "@vaultinum/app-sdk";
import { useContext } from "react";

import { Translation } from "../../i18n";

export default function StepDepositRightsUpdate(): JSX.Element {
    const { deposit } = useContext(InterdepositContext);
    const { translation } = useI18n<Translation>();

    return <Info>{deposit?.holders && translation.stepDepositRightsUpdateInfo(deposit.holders)}</Info>;
}
