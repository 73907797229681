import { CreditCardIcon, Layout, Loader, MemberInvoicesList, Tabs, UserContext, useI18n } from "@vaultinum/app-sdk";
import { Translation } from "../../i18n";
import { useContext } from "react";

export enum InvoicesAndPaymentTabs {
    PAYMENT = "payment",
    INVOICES = "invoices"
}

export default function InvoicesAndPaymentsPage(): JSX.Element {
    const { user } = useContext(UserContext);
    const { translation } = useI18n<Translation>();

    const tabs = [
        {
            key: InvoicesAndPaymentTabs.PAYMENT,
            title: translation.payments,
            component: <p>{translation.noResult}</p>
        },
        {
            key: InvoicesAndPaymentTabs.INVOICES,
            title: translation.invoices,
            component: (user?.memberId && <MemberInvoicesList memberId={user?.memberId} />) || <Loader />
        }
    ];

    return (
        <Layout title={translation.paymentAndResumeInvoices} icon={<CreditCardIcon />}>
            <Tabs tabs={tabs} />
        </Layout>
    );
}
