export const SIZES = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"] as const;
export type Size = (typeof SIZES)[number];

export const SIZE_MAP: Record<Size, string> = {
    xxs: "size-2",
    xs: "size-3",
    sm: "size-4",
    md: "size-5",
    lg: "size-6",
    xl: "size-7",
    xxl: "size-16"
};