import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";

import { useOutsideClick } from "../../utils/hooks";
import { ChevronSmall } from "../Icons";
import Input from "../Input/Input";

export interface GroupListOption {
    groupName: string;
    list: ListOption[];
}

export interface ListOption {
    id: string;
    label: string;
    disabled?: boolean;
    type?: "checkbox" | "radio";
}

export function InputSelectCheckbox({
    list,
    inputLabel,
    noResultLabel,
    listFiltered,
    handleList,
    placeholder
}: {
    list: ListOption[] | GroupListOption[] | [];
    inputLabel: string;
    noResultLabel: string;
    listFiltered?: ListOption[];
    handleList?: (list: ListOption[]) => void;
    placeholder?: string;
}): JSX.Element {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<ListOption[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const ref = useOutsideClick<HTMLInputElement>(() => setExpanded(false));
    const [currentList, setCurrentList] = useState<ListOption[] | GroupListOption[]>(list);
    const [ids, setIds] = useState<string[]>([]);

    useEffect(() => {
        if (listFiltered) {
            setSelectedOptions(listFiltered);
        }
    }, [listFiltered]);

    const onFilter = (value: string) => {
        const foundItem = list.find((item: ListOption | GroupListOption) => {
            if ("id" in item) {
                return item.id === value;
            } else if ("list" in item) {
                return item.list.some(subItem => subItem.id === value);
            }
            return false;
        });

        if (foundItem) {
            if ("id" in foundItem) {
                const temporaryOptions = selectedOptions ? [...selectedOptions] : [];
                const optionIndex = temporaryOptions?.findIndex(item => item.id === foundItem.id);
                let newOptions: ListOption[] = [];

                if (optionIndex === -1) {
                    newOptions = [...temporaryOptions, { id: foundItem.id, label: foundItem.label }];
                    setSelectedOptions(newOptions);
                } else {
                    newOptions = temporaryOptions.filter(item => item.id !== foundItem.id);
                    setSelectedOptions(newOptions);
                }
                handleList?.(newOptions);
            } else if ("list" in foundItem) {
                const temporaryOptions = selectedOptions ? [...selectedOptions] : [];
                const subItem = foundItem.list.find(sub => sub.id === value);
                let newGroupOptions: ListOption[] = [];

                if (subItem) {
                    const optionIndex = temporaryOptions.findIndex(item => item.id === subItem.id);

                    if (optionIndex === -1) {
                        newGroupOptions = [...temporaryOptions, { id: subItem.id, label: subItem.label }];
                        setSelectedOptions(newGroupOptions);
                    } else {
                        newGroupOptions = temporaryOptions.filter(item => item.id !== subItem.id);
                        setSelectedOptions(newGroupOptions);
                    }
                    handleList?.(newGroupOptions);
                }
            }
        }
    };

    useEffect(() => {
        if (selectedOptions) {
            setIds(selectedOptions.map(item => item.id));
        }
    }, [selectedOptions]);

    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        setInputValue(e.target.value);
    };

    useEffect(() => {
        if (inputValue) {
            setExpanded(true);

            let filteredList: ListOption[] | GroupListOption[] = [];

            if ("id" in list[0]) {
                filteredList = (list as ListOption[]).filter(item => {
                    return item.label.toLowerCase().includes(inputValue.toLowerCase());
                });
            }

            if ("groupName" in list[0]) {
                filteredList = (list as GroupListOption[]).map(item => {
                    return {
                        groupName: item.groupName,
                        list: item.list.filter(subItem => subItem.label.toLowerCase().includes(inputValue.toLowerCase()))
                    };
                });

                filteredList = filteredList.filter(item => item.list.length);
            }

            setCurrentList(filteredList);
        } else {
            setCurrentList(list);
        }
    }, [inputValue, list]);

    const handleClick = () => {
        setExpanded(prev => !prev);
    };

    return (
        <div ref={ref} className="relative">
            <label className="text-sm font-bold text-label">{inputLabel}</label>
            <div className="relative">
                <div onClick={handleClick}>
                    <Input type="text" value={inputValue} onChange={onInputChange} placeholder={placeholder} />
                    <ChevronSmall
                        className={classNames("w-3 fill-primary ease-in-out duration-200 mr-1 absolute right-3 top-1/2 cursor-pointer", {
                            "rotate-0": expanded,
                            "rotate-180": !expanded
                        })}
                    />
                </div>

                {expanded && (
                    <ul className="w-full absolute mt-1 p-2 border border-gray-300 z-10 text-sm bg-white rounded-none overflow-scroll">
                        {currentList.length ? (
                            <>
                                {currentList.map(item => {
                                    if ("groupName" in item) {
                                        return (
                                            <Fragment key={item.groupName}>
                                                <p className="px-2 py-1.5 font-semibold pb-2.5 pt-2.5">{item.groupName}</p>
                                                {item.list.map(subItem => (
                                                    <li className="flex gap-3.5 hover:bg-slate-100 px-2 py-1.5" key={subItem.id}>
                                                        <Input
                                                            type={subItem.type ?? "checkbox"}
                                                            fieldName={subItem.id}
                                                            onChange={() => onFilter(subItem.id)}
                                                            checked={ids.includes(subItem.id)}
                                                            label={subItem.label}
                                                            disabled={subItem.disabled}
                                                        />
                                                    </li>
                                                ))}
                                            </Fragment>
                                        );
                                    } else {
                                        const { id, label, disabled } = item;
                                        return (
                                            <li className="flex items-center gap-3.5 hover:bg-slate-100 px-2 py-1.5" key={id}>
                                                <Input
                                                    type="checkbox"
                                                    fieldName={id}
                                                    onChange={() => onFilter(id)}
                                                    checked={ids.includes(id)}
                                                    label={label}
                                                    disabled={disabled}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                            </>
                        ) : (
                            <li className="flex gap-3.5 hover:bg-slate-100">
                                <span className="block text-sm text-label">{noResultLabel}</span>
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
}
