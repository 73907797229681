import { Member, MemberApi, getMemberApi, getMemberDetails, useApi } from "@vaultinum/app-sdk";
import { ReactNode, createContext, useEffect, useState } from "react";

export interface MemberContextInterface {
    member?: Member;
}

export const MemberContext = createContext<MemberContextInterface>({} as MemberContextInterface);

export function MemberContextProvider({ children }: { children: ReactNode }) {
    const [member, setMember] = useState<Member>();

    const { fetchApi } = useApi();

    useEffect(() => {
        void (async () => {
            const memberApi = await fetchApi<MemberApi>(getMemberApi);
            if (memberApi) {
                const memberFn = getMemberDetails(memberApi);
                if (memberFn) {
                    setMember(await fetchApi(memberFn));
                }
            }
        })();
    }, []);

    return <MemberContext.Provider value={{ member }}>{children}</MemberContext.Provider>;
}
